import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useContext, useEffect } from 'react';
import HomeIntro from 'UI/sections/HomeIntro';
import { loadDuck } from 'UI/components/Duck/DuckWrapper/utils/helpers';
import MetaTags from 'components/Common/MetaTags';
import { PAGES } from 'utils/constants';
import { AppContext } from 'utils/appContext';

const SectionSelector = dynamic(() => import('containers/Home/SectionSelector'));

export const Home = ({
  theme,
  introSection,
  pageMetadata,
  pageData,
  type,
  ...rest
}) => {
  const contentModules = pageData;

  const { contextData: { duck }, setContextData } = useContext(AppContext);

  useEffect(() => {
    if (duck) {
      return;
    }

    (async () => {
      const duckLoaded = await loadDuck();

      setContextData((prev) => ({ ...prev, duck: duckLoaded }));
    })();
  }, [duck, setContextData]);

  return (
    <>
      <MetaTags
        page={PAGES.homepage}
        pageMetadata={pageMetadata}
      />
      <HomeIntro
        theme={theme}
        introSection={introSection}
      />
      <main>
        {contentModules?.map((module, i) => (
          <SectionSelector
            key={`section/${i}`}
            section={module}
            type={type}
            {...rest}
          />
        ))}
      </main>
    </>
  );
};

Home.propTypes = {
  introSection: PropTypes.instanceOf(Object).isRequired,
  pageMetadata: PropTypes.instanceOf(Object).isRequired,
  theme: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Home;
